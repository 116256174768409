import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Medical Equipment Management Systems</h1>
            </header>
            <div className="content">
                <p>We provide custom engineering services for the medical industry, including solutions for clinical video recording and software development including forms, reports and software configurations.</p>
                <p>Our web development services offer a high level of versatility to cater for both clinical and non-clinical needs. We can design and develop an effective website to increase your internet visibility and help to advertise/mantain your medical or technical ideas, either as a stand alone section of an existing website or as a brand new site.</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
