import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import workflow_management from '../assets/images/workflow_management.jpg'
import asset_management from '../assets/images/asset_management.jpg'
import device_traceability from '../assets/images/device_traceability.jpg'
import equipment_management from '../assets/images/equipment_management.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="MedEMS | Medical Equipment Management Systems"
                    meta={[
                        { name: 'description', content: 'Sample' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${workflow_management})`}}>
                            <header className="major">
                                <h3>Workflow Management</h3>
                                <p>comprehensive asset management system for medical device service providers</p>
                            </header>
                            <Link to="/workflow" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${asset_management})`}}>
                            <header className="major">
                                <h3>Asset Management</h3>
                                <p>standard CEMS for hospitals and healthcare organisations</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${device_traceability})`}}>
                            <header className="major">
                                <h3>Medical Device Traceability</h3>
                                <p>tracking system for medical device usage, inspection and decontamination</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${equipment_management})`}}>
                            <header className="major">
                                <h3>Plant & Facility Equipment Management</h3>
                                <p>online system for proactive facility maintenance</p>
                            </header>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>MedEMS products</h2>
                            </header>
                            <p>MedEMS Workflow Management Solutions are powerful, reliable, easy to use and cost effective web based tools which enable users to record and update equipment data, such as inventory and maintenance records, via any web browser. These systems are based on the principle of electronic document circulation. It allows the organisation to significantly reduce the number of paper records, providing a transparent management process and ensuring the automation and standardisation of all service/management procedures.</p>
                            <ul className="actions">
                                <li><Link to="/workflow" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex